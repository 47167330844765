import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

interface Props {
	data: {
		site: {
			siteMetadata: {
				title: string
			}
		}
	}
	location: Location
}

const NotFoundPage = (): ReactElement => {
	return (
		<Layout>
			<SEO title="404: Not Found" />
			<h1>Not Found</h1>
			<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
		</Layout>
	)
}

export default NotFoundPage

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`
